import ReactDOM from "react-dom/client";
import App from "./App";
import {
  init,
  browserTracingIntegration,
  replayIntegration,
  feedbackIntegration,
} from "@sentry/react";

import "react-responsive-modal/styles.css";
import "./index.css";
import React from "react";

const ENV = import.meta.env.VITE_ENV;

// Sentry
init({
  dsn: "https://6456bf3eb6c4d794fa744dd2cbafc28e@o4504765510123520.ingest.us.sentry.io/4507533342670848",
  environment: ENV,
  debug: ENV === "local",
  enabled: ENV === "production",
  maxBreadcrumbs: 50,
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
    feedbackIntegration({
      autoInject: false,
      showBranding: false,
      colorScheme: "dark",
      showName: false,
      showEmail: false,
      formTitle: "Send Feedback",
      buttonLabel: "Feedback",
      submitButtonLabel: "Send Feedback",
      messagePlaceholder: "Got a suggestion or need help? Let us know!",
      themeDark: {
        background: "#1d1d1d",
        outline: "none",
      },
      tags: {
        feedback: true,
      },
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost"], //, TODO: check these ["auth.really.com", "production.really.com"],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.5,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
