import React, { useState, useEffect, useRef } from "react";
import {
  type ConfirmationResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAdditionalUserInfo,
} from "firebase/auth";
import { auth } from "firebase";
import tw from "twin.macro";
import { toE164 } from "@utils/formatters";
import { isPhoneNumberComplete, validateInput } from "@utils/validations";
import { InputField } from "@components/subscription/InputField";
import OPTInput from "./OPTInput";
import Button from "@components/Button";
import { useAuth } from "@context/authContext";
import { useNavigate } from "react-router-dom";
import { NavRoutes } from "@constants/routes";
const Paragraph = tw.p`text-[20px] font-normal leading-tight mb-[20px]`;
declare const grecaptcha: any;

const PhoneOrEmailLogin: React.FC<any> = ({ onChange, clearErrors, error, name, setError }) => {
  const { setUser, logout } = useAuth();
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneVerifiedError, setPhoneVerifiedError] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | null>(null);
  const [verifying, setVerifying] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);

  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null);
  const recaptchaWidgetIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (recaptchaVerifierRef.current) return;

    recaptchaVerifierRef.current = new RecaptchaVerifier(auth, "captcha-placeholder", {
      size: "invisible",
    });

    recaptchaVerifierRef.current.render().then((widgetId) => {
      recaptchaWidgetIdRef.current = widgetId;
    });
  }, []);

  const onSignInSubmit = () => {
    setSendingOtp(true);
    const appVerifier = recaptchaVerifierRef.current!;
    const phoneToFirebase = toE164(phoneNumber);

    signInWithPhoneNumber(auth, phoneToFirebase, appVerifier)
      .then((confirmationResult) => {
        setConfirmationResult(confirmationResult);
      })
      .catch((error) => {
        // TODO don't show alert, handle issue better
        alert(`Error during signInWithPhoneNumber:\n\n${error.code}\n\n${error.message}`);
      })
      .finally(() => {
        setSendingOtp(false);
      });
  };

  const onVerifyCodeSubmit = (verificationCode: string) => {
    setVerifying(true);

    confirmationResult!
      .confirm(verificationCode)
      .then(async (result) => {
        const info = getAdditionalUserInfo(result);

        if (info === null || info.isNewUser) {
          result.user.delete();
          logout();
          // TODO replace alert with error in form or some toast
          setError(name, {
            type: "custom",
            message:
              "phone number not found. try signing in with your email or a different phone number.",
          });
          setConfirmationResult(null);
          setPhoneVerifiedError(false);
          return;
        }

        setUser(result.user as unknown as any);
        navigate(`/${NavRoutes.Dashboard}`);
        setConfirmationResult(null);
        setPhoneVerifiedError(false);
        clearErrors(name);
      })
      .catch(() => {
        setPhoneVerifiedError(true);
      })
      .finally(() => {
        setVerifying(false);
      });
  };

  const onCancelClick = () => {
    setConfirmationResult(null);
    setPhoneVerifiedError(false);
    if (grecaptcha && recaptchaWidgetIdRef.current !== null) {
      grecaptcha.reset(recaptchaWidgetIdRef.current);
    }
  };

  const { type, value } = validateInput(phoneNumber);
  const isComplete = type === "phone" && isPhoneNumberComplete(value);

  return (
    <div className="w-full">
      <div id="captcha-placeholder" className="hidden" />
      {!confirmationResult && (
        <>
          <InputField
            name={name}
            className="bg-really-gray-800 text-really-gray-400 font-medium"
            placeholder="your phone or email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { type, value } = validateInput(e.target.value);
              setPhoneNumber(value);
              onChange({ type, value });

              // this code needed for ENG-1260
              const cursorPosition = e.target.selectionStart!;
              const adjustment = e.target.value.length - value.length;
              const newCursorPosition = Math.max(0, cursorPosition - adjustment);
              setTimeout(() => e.target.setSelectionRange(newCursorPosition, newCursorPosition), 0);
            }}
            value={phoneNumber}
            error={error}
          />
          {type === "phone" && (
            <Button
              loading={sendingOtp}
              label="send SMS code"
              onClick={onSignInSubmit}
              className={`block overflow-hidden font-[200] h-[55px] w-full bg-really-purple-haze rounded-full transition-all duration-700 ease-in-out
                ${isComplete ? "opacity-100 max-h-screen mt-5" : "opacity-0 max-h-0 mt-0"}`}
            />
          )}
        </>
      )}
      {confirmationResult && (
        <>
          <Paragraph>enter verification code we sent to {phoneNumber}</Paragraph>
          <OPTInput
            error={phoneVerifiedError}
            onChange={(val: string) => {
              setPhoneVerifiedError(false);
              if (val.length === 6) onVerifyCodeSubmit(val);
            }}
          />
          {phoneVerifiedError && <p className="text-red mt-2">incorrect code, please try again.</p>}
          <div>
            <Button
              label="cancel"
              loading={verifying}
              onClick={onCancelClick}
              className="bg-white text-black h-[55px] w-full mt-[20px] rounded font-normal"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PhoneOrEmailLogin;
