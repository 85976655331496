import { type SeverityLevel, captureException, captureMessage } from "@sentry/react";

type Props = {
  err: any;
  payload?: any;
  message: string;
  level?: SeverityLevel;
};
export const handleApiError = ({ err, payload, message, level }: Props) => {
  const hasResponse = err?.response?.data;
  const errorMessage = hasResponse?.error || err;
  const errorPayload = payload ? JSON.stringify(payload) : "No payload";
  const responsePayload = hasResponse ? JSON.stringify(hasResponse) : "No response data";

  captureException(errorMessage instanceof Error ? errorMessage : new Error(errorMessage), {
    level: level || "error",
    extra: {
      message,
      payload: errorPayload,
      response: responsePayload,
    },
  });
};

export enum PortingErrorType {
  VerizonFraud = "verizonFraud",
  Fraud = "fraud",
  Pin = "pin",
  AccountNumber = "account_number",
  PortComplexity = "port_complexity",
  ZipCode = "zip_code",
  Unknown = "unknown",
  ReachCurrentProvider = "reach_current_provider",
}

const errorPatterns = {
  [PortingErrorType.VerizonFraud]: ["6P-REMOVE NUMBER LOCK ON VERIZON ACCOUNT"],
  [PortingErrorType.Fraud]: [
    "6B-FRAUD_PROTECTION",
    "6P-MDN has service provider port protection",
    "6B-Fraud Protection",
  ],
  [PortingErrorType.Pin]: [
    "6P-Cricket Number Transfer PIN required or incorrect",
    "6B-VZW Number Transfer PIN needed OR Incorrect",
    "8C-Password/PIN required or incorrect",
    "8C-PASSWORD/PIN IS REQUIRED OR INCORRECT",
    "6B-ATT Number Transfer PIN Needed or Incorrect",
  ],
  [PortingErrorType.AccountNumber]: [
    "8A-Account number required or incorrect",
    "8A-ACCOUNT_NUM_REQ_OR_INCORRECT",
  ],
  [PortingErrorType.ReachCurrentProvider]: [
    "6C-Customer information does not match",
    "6D-MDN NOT ACTIVE",
    "6B-SAME_NEW_OLD_NET_SP",
  ],
  [PortingErrorType.PortComplexity]: ["6G-Port Complexity", "6H-", "6J-"],
  [PortingErrorType.ZipCode]: ["8D-Zip Code required or incorrect"],
  [PortingErrorType.Unknown]: [],
};

export const getPortingError = (description: string) => {
  for (const [errorType, patterns] of Object.entries(errorPatterns)) {
    if (patterns.some((pattern) => description.includes(pattern))) {
      return errorType as PortingErrorType;
    }
  }

  captureMessage("unhandled porting error", {
    level: "warning",
    tags: {
      porting: true,
    },
    extra: {
      description,
    },
  });
  return PortingErrorType.Unknown;
};
