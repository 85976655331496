import axios from "axios";
import {
  type PostPortResponse,
  type PutPortResponse,
  type AccountInfo,
  type PortStatusResponse,
} from "@hooks/usePortIn";
import { type UpdateLinePayload, type UpdateLineResponse } from "@hooks/useUpdateLine";
import { type I911Address } from "@hooks/useUpdateE911Address";
import { type CustomerInfo } from "@hooks/useCustomerInfo";

interface StripeConfigResponse {
  url: string;
  message: string;
}

interface PortPayload {
  phone: string;
}

interface PortResponse {
  eligible: boolean;
  reason: string;
  provider_name: string;
  provider_id: string;
}

interface SignUpItem {
  number_to_port?: string;
  phone_brand: string;
  phone_model: string;
  plan_code: string;
  quantity: number;
  is_phone_unlocked: boolean;
  zip_code: string;
  is_esim: boolean;
}

interface PaymentDetails {
  coupon_code: string;
  items: Array<Partial<SignUpItem>>;
  zip_code: string;
}

interface NewLinePayload {
  coupon_code: string;
  external_id: string;
  is_esim: true;
  number_to_port: string;
  phone_brand: string;
  phone_model: string;
  plan_code: string;
  price: number;
  zip_code: string;
}

interface NewLineResponse {
  external_id: string;
}

interface NewLineStatus {
  error: "string";
  is_complete: true;
}

export interface PaymentDetailsResponse {
  sub_total: number;
  total: number;
  discount: number;
  tax: number;
  message?: string;
}

// Create an Axios instance
export const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BASE_URL,
});

export const postPortIn = async (payload: AccountInfo) => {
  return await axiosInstance
    .post<PostPortResponse>("/api/v1/subscriber/port-in", payload)
    .then((res) => res.data);
};

export const updatePortIn = async (payload: AccountInfo) => {
  return await axiosInstance
    .put<PutPortResponse>("/api/v1/subscriber/port-in", payload)
    .then((res) => res.data);
};

export const getPortInStatus = async (enrollmentId: string) => {
  return await axiosInstance
    .get<PortStatusResponse>(`/api/v1/subscriber/port-in-status/${enrollmentId}`)
    .then((res) => res.data);
};

export const updateActiveLineStatus = async (payload: UpdateLinePayload) => {
  return await axiosInstance
    .post<UpdateLineResponse>("/api/v1/subscriber/activate-line", payload)
    .then((res) => res.data);
};

export const getActivationStatus = async (enrollmentId: string) => {
  return await axiosInstance
    .get<UpdateLineResponse>(`/api/v1/subscriber/line-status/${enrollmentId}`)
    .then((res) => res.data);
};

export const getStripeUpdatePayment = async () => {
  return await axiosInstance
    .get<StripeConfigResponse>("/api/v1/subscriber/payment-login-link")
    .then((res) => res.data);
};

export const getStripePaymentHistory = async () => {
  return await axiosInstance
    .get<StripeConfigResponse>("/api/v1/subscriber/invoice-login-link")
    .then((res) => res.data);
};

export const getCustomerInfo = async () => {
  return await axiosInstance
    .get<CustomerInfo>("/api/v1/subscriber/customer-info")
    .then((res) => res.data);
};

export const checkPortability = async (payload: PortPayload): Promise<PortResponse> => {
  return await axiosInstance
    .post<PortResponse>("/subscription/check-portability", payload)
    .then((res) => res.data);
};

export const getPaymentDetails = async (
  payload: PaymentDetails,
): Promise<PaymentDetailsResponse> => {
  return await axiosInstance
    .post<PaymentDetailsResponse>("/subscription/payment-details", payload)
    .then((res) => res.data);
};

export const addNewLine = async (payload: NewLinePayload): Promise<NewLineResponse> => {
  return await axiosInstance
    .post<NewLineResponse>("/api/v1/subscriber/add-line-async", payload)
    .then((res) => res.data);
};

export const getNewLineStatus = async (externalId: string) => {
  return await axiosInstance
    .get<NewLineStatus>(`/api/v1/subscriber/add-line-status/${externalId}`)
    .then((res) => res.data);
};

// export const getCustomerInfo = async () => {
//   console.log("getCustomerInfo called");
//   return await new Promise((resolve) => {
//     setTimeout(
//       () =>
//         resolve({
//           lines: [
//             {
//               plan: {
//                 id: 112,
//                 code: 2,
//                 name: "REALLY Clutch",
//                 description: "REALLY Clutch",
//                 price: 39,
//                 start_date: "NA",
//                 expiry_date: "NA",
//                 taxes_and_fees: true,
//               },
//               phone_number: "7372871111",
//               status: "Dispatched",
//               port_number: "7372871111",
//               activated: true,
//               enrollment_id: "A15305",
//               phone_brand: "apple",
//               phone_model: "apple_iphone_15_pro_max",
//               qr_code: "LPA:1$t-mobile.idemia.io$0FF1B67DFA6F613CA2DD3894A880DAF1",
//               wifi_calling_enabled: false,
//               label: "",
//             },
//             {
//               plan: {
//                 id: 52,
//                 code: 1,
//                 name: "REALLY Limitless",
//                 description: "REALLY Limitless",
//                 price: 129,
//                 start_date: "NA",
//                 expiry_date: "NA",
//                 taxes_and_fees: true,
//               },
//               phone_number: "0",
//               status: "Active",
//               port_number: "0",
//               activated: true,
//               enrollment_id: "A15411",
//               phone_brand: "google",
//               phone_model: "google_pixel_8",
//               qr_code: "LPA:1$t-mobile.idemia.io$0FF1B67DFA6F613CA2DD3894A880DAF1",
//               wifi_calling_enabled: false,
//               label: "",
//             },
//             {
//               plan: {
//                 id: 52,
//                 code: 1,
//                 name: "REALLY Limitless",
//                 description: "REALLY Limitless",
//                 price: 129,
//                 start_date: "NA",
//                 expiry_date: "NA",
//                 taxes_and_fees: true,
//               },
//               phone_number: "7372871111",
//               port_number: "7372871111",
//               status: "Dispatched",
//               activated: false,
//               enrollment_id: "A15497",
//               phone_brand: "google",
//               phone_model: "google_pixel_8",
//               qr_code: "LPA:1$t-mobile.idemia.io$0FF1B67DFA6F613CA2DD3894A880DAF1",
//               wifi_calling_enabled: false,
//               label: "",
//             },
//           ],
//           verification: {
//             type: "recovery phrase",
//             is_verified: true,
//           },
//         }),
//       1000,
//     );
//   });
// };

export const apiVerifyEmail = async (token: string) => {
  return await axiosInstance
    .get("/api/v1/subscriber/verification/email", { headers: { "x-jwt": token } })
    .then((res) => res.data)
    .catch((err) => {
      console.error("Error in apiVerifyEmail:", err);
    });
};
// export const getRecoveryPhrase = async () => {
//   return await Promise.resolve({
//     value: "champion woman build nose panel leopard design scorpion rotate mountain lizard guard",
//   });
// };

export const getRecoveryPhrase = async () => {
  return await axiosInstance
    .get("/api/v1/subscriber/verification/recovery-phrase")
    .then((res) => res.data)
    .catch((err) => err?.response?.data?.message);
};

export const verifyPhrase = async (phrase: string) => {
  return await axiosInstance
    .post("/api/v1/subscriber/verification/validate-recovery-phrase", { value: phrase })
    .then((res) => res.data)
    .catch((err) => ({
      error: err,
    }));
};

// export const verifyPhrase = async (phrase: string) => {
//   return await Promise.resolve({
//     attempts: 0,
//     status: true,
//   });
// };

export const getE911Address = async (enrollmentId: string) => {
  return await axiosInstance
    .get<I911Address>(`/api/v1/subscriber/${enrollmentId}/e911/`)
    .then((res) => res.data);
};

export const postE911Address = async (payload: Partial<I911Address>) => {
  return await axiosInstance.post("/api/v1/subscriber/e911", payload).then((res) => res.data);
};

export const updateSimCard = async (enrollmentId: string) => {
  return await axiosInstance
    .put<{ qr_code: string }>(`/api/v1/subscriber/${enrollmentId}/sim/`)
    .then((res) => res.data);
};

export const updateLineLabel = async (enrollmentId: string, label: string) => {
  return await axiosInstance
    .put(`/api/v1/subscriber/line-label`, { enrollment_id: enrollmentId, label })
    .then((res) => res.data);
};
