import { LineStatus, type CustomerInfo } from "@hooks/useCustomerInfo";

const DEFAULT_ZIPCODE = "78701";

export const getLineStatus = (line: ILine): LineStatus => {
  const waitingTelgooToUpdatePortIn = line.activated && line.status === LineStatus.Dispatched;

  if (line.status === LineStatus.Active || waitingTelgooToUpdatePortIn) return LineStatus.Active;

  return LineStatus.Inactive;
};

export const getPrimaryEnrollmentId = (customerInfo?: CustomerInfo) => {
  if (!customerInfo || !Array.isArray(customerInfo.lines)) {
    console.error("getPrimaryEnrollmentId: customerInfo is undefined");
    return undefined;
  }

  const primaryLine = customerInfo.lines.find((line) => line.is_primary_enrollment);
  return primaryLine?.enrollment_id ?? customerInfo.lines?.[0]?.enrollment_id;
};

export const getCustomerZipcode = (customerInfo?: CustomerInfo) => {
  if (!customerInfo || !Array.isArray(customerInfo.lines)) {
    console.error("getCustomerZipcode: customerInfo is undefined");
    return DEFAULT_ZIPCODE;
  }

  const line = customerInfo.lines.find((line) => line.zip_code);
  return line?.zip_code ?? DEFAULT_ZIPCODE;
};

// dispatched means line is waiting for activation
export const getFirstDispatchedLine = (data?: CustomerInfo) => {
  if (!data?.lines?.length) return null;

  return data.lines.find((line) => line.status === LineStatus.Dispatched && !line.activated);
};
