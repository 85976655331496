import React, { useState, useEffect, type ReactNode, useCallback } from "react";
// @ts-expect-error no types available
import sessionstorage from "sessionstorage";
import { SubscriptionDataContext, type SubscriptionFlowData } from "./subscriptionContext";

const initialData = {
  skipActivation: false,
};

const initialNavigation = {
  goBack: () => {
    window.history.back();
  },
};

const fetchSessionData = async (cb: (data: SubscriptionFlowData) => void): Promise<void> => {
  const sessionData: string = await sessionstorage.getItem("data");

  if (sessionData) {
    cb(JSON.parse(sessionData));
  } else {
    cb(initialData as unknown as SubscriptionFlowData);
  }
};

export const SubscriptionProvider = ({
  children,
}: {
  children: ReactNode;
}): React.ReactElement | null => {
  const [data, setData] = useState<Partial<SubscriptionFlowData> | undefined>();
  const [navigation, setNavigation] = useState(initialNavigation);

  useEffect(() => {
    fetchSessionData(setData).catch((e) => {
      console.log(e);
    });
  }, []);

  const addData = (newData: Partial<SubscriptionFlowData>) => {
    setData((prevData) => {
      const updatedData = { ...prevData, ...newData };
      sessionstorage.setItem("data", JSON.stringify(updatedData));
      return updatedData;
    });
  };

  const setGoBack = useCallback((fn: () => void): void => {
    setNavigation((prevNavigation) => {
      return {
        ...prevNavigation,
        goBack: fn,
      };
    });
  }, []);

  if (!data) return null;

  return (
    <SubscriptionDataContext.Provider
      value={{
        data: data as SubscriptionFlowData,
        addData,
        navigation,
        setGoBack,
      }}
    >
      {children}
    </SubscriptionDataContext.Provider>
  );
};
